var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "items-center justify-items-center" },
    [
      _vm._t("error-header"),
      _c(
        "form",
        { staticClass: "flex flex-col justify-start space-y-4 rounded" },
        [
          _c(
            "div",
            {
              staticClass: "flex space-x-2 cursor-pointer",
              on: { click: _vm.selectedBack }
            },
            [
              _c(
                "div",
                { staticClass: "inline" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "arrow-left"] }
                  })
                ],
                1
              ),
              _c("h1", { staticClass: "inline mb-4 font-bold text-gray-700" }, [
                _vm._v("Create an account")
              ])
            ]
          ),
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm font-bold text-gray-700",
                attrs: { for: "name" }
              },
              [_vm._v(" Display name ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              staticClass: "skyhub-input-field",
              attrs: { id: "name", type: "text", autocomplete: "family-name" },
              domProps: { value: _vm.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm font-bold text-gray-700",
                attrs: { for: "email" }
              },
              [_vm._v(" Email address ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "skyhub-input-field",
              attrs: { id: "email", type: "text", autocomplete: "email" },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm font-bold text-gray-700",
                attrs: { for: "password" }
              },
              [_vm._v("Create a password")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }
              ],
              staticClass: "skyhub-input-field",
              attrs: {
                id: "password",
                type: "password",
                autocomplete: "new-password"
              },
              domProps: { value: _vm.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "mb-6" }, [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm font-bold text-gray-700",
                attrs: { for: "password" }
              },
              [_vm._v("Confirm password")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordConfirm,
                  expression: "passwordConfirm"
                }
              ],
              staticClass: "skyhub-input-field",
              attrs: {
                id: "password-confirm",
                type: "password",
                autocomplete: "new-password"
              },
              domProps: { value: _vm.passwordConfirm },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.passwordConfirm = $event.target.value
                }
              }
            })
          ]),
          _c("div", [
            _c("ul", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.acceptLegal,
                    expression: "acceptLegal"
                  }
                ],
                staticClass: "inline",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.acceptLegal)
                    ? _vm._i(_vm.acceptLegal, null) > -1
                    : _vm.acceptLegal
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.acceptLegal,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.acceptLegal = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.acceptLegal = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.acceptLegal = $$c
                    }
                  }
                }
              }),
              _vm._v(" I have read and accept the "),
              _c(
                "a",
                { staticClass: "inline underline", attrs: { href: "" } },
                [_vm._v("Terms of Service")]
              ),
              _vm._v(" and "),
              _c(
                "a",
                { staticClass: "inline underline", attrs: { href: "" } },
                [_vm._v("Privacy Policy")]
              )
            ])
          ]),
          _c("div", { staticClass: "flex items-center justify-end" }, [
            _c(
              "button",
              {
                staticClass:
                  "px-4 py-2 text-white bg-black border-2 border-black border-none rounded disabled:bg-gray-400 font-roboto",
                attrs: {
                  type: "button",
                  disabled: !_vm.valid,
                  "data-cy": "signup-button"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("signup", {
                      name: _vm.name,
                      email: _vm.email,
                      password: _vm.password,
                      passwordConfirm: _vm.passwordConfirm
                    })
                  }
                }
              },
              [_vm._v(" Create an account ")]
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./SignupFormErrorHeader.vue?vue&type=template&id=64689973&"
import script from "./SignupFormErrorHeader.vue?vue&type=script&lang=ts&"
export * from "./SignupFormErrorHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/admin/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64689973')) {
      api.createRecord('64689973', component.options)
    } else {
      api.reload('64689973', component.options)
    }
    module.hot.accept("./SignupFormErrorHeader.vue?vue&type=template&id=64689973&", function () {
      api.rerender('64689973', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/login/SignupFormErrorHeader.vue"
export default component.exports





















































































import { Component, Vue } from 'vue-property-decorator';

@Component<SignupFormComponent>({})
export default class SignupFormComponent extends Vue {
  private name = '';
  private email = '';
  private password = '';
  private passwordConfirm = '';
  private acceptLegal = false;

  private selectedBack() {
    this.$emit('selected-back');
  }

  private get valid() {
    return (
      this.name.length !== 0 &&
      this.email.length !== 0 &&
      this.password.length !== 0 &&
      this.acceptLegal == true
    );
  }
}




















import { Component, Vue } from 'vue-property-decorator';
import SignupFormComponent from '@/components/login/SignupFormComponent.vue';
import SignupFormFields, {
  PASSWORD_DOES_NOT_MATCH
} from '@/components/login/SignupFormFields';
import { AuthClient } from '@/services/auth';
import SignupFormErrorHeader from '@/components/login/SignupFormErrorHeader.vue';

@Component<SignUpPage>({
  components: {
    SignupFormComponent,
    SignupFormErrorHeader
  }
})
export default class SignUpPage extends Vue {
  private error = '';

  private onClearError() {
    this.error = '';
  }

  private onSignup({
    name,
    email,
    password,
    passwordConfirm
  }: SignupFormFields) {
    if (password !== passwordConfirm) {
      this.error = PASSWORD_DOES_NOT_MATCH;
      return;
    }
    this.startSignup({ name, email, password, passwordConfirm });
  }

  private onSelectBack() {
    this.$router.back();
  }

  private async startSignup(signupFormFields: SignupFormFields) {
    this.error = '';
    try {
      // Create new user with email and password through the firebase SDK
      const user = await AuthClient.getInstance().createUserWithEmailAndPassword(
        signupFormFields.email,
        signupFormFields.password
      );
      if (user) {
        // Update display name
        await user.updateProfile({
          displayName: signupFormFields.name,
          photoURL: ''
        });
        // nav to confirmation page
        this.$router.push({
          name: 'verification'
        });
      }
    } catch (err) {
      this.error = err;
    }
  }
}

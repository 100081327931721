var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.error
      ? _c(
          "button",
          {
            staticClass:
              "relative px-4 py-3 text-red-700 bg-red-100 border border-red-400 rounded",
            attrs: { role: "alert" },
            on: {
              click: function($event) {
                return _vm.$emit("clear-error")
              }
            }
          },
          [
            _c("span", { staticClass: "block sm:inline" }, [
              _vm._v(_vm._s(_vm.error))
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }